import { Component } from 'react';
import './reset.scss';
import './App.scss';
// import { StatusBar, Style } from '@capacitor/status-bar';

export default class App extends Component {
    constructor () {
        super();
        this.state = {
            warmup: 10,
            intervalDur: 40,
            pause: 20,
            repetitions: 15,
            started: false,
            paused: false,
            timer: 0,
            timeSheet: [],
        }

        this.bleep1 = new Audio('audio/bleep1.mp3');
        this.bleep1.preload = 'auto';
        this.bleep2 = new Audio('audio/bleep2.mp3');
        this.bleep2.preload = 'auto';
    
        // StatusBar.setStyle({ style: Style.Dark });
    }

    componentWillUnmount() {
        this.stop();
    }

    change = (event, unit) => {
        this.setState({
            [unit]: event.target.value
        })
    }
    
    validate = (event, unit) => {
        const value = parseInt(event.target.value);
        const min = parseInt(event.target.min) || 0;
        const max = parseInt(event.target.max) || 1000;
        const cleanValue = value > max ? max : Math.abs(value) || min;
        this.setState({
            [unit]: cleanValue,
        })
    }

    duration = () => {
        const timerDuration = this.state.intervalDur * this.state.repetitions;
        const pauseDuration = this.state.pause * (this.state.repetitions - 1); // last iteration has no pause!
        return timerDuration + pauseDuration + this.state.warmup || 0;
    }

    setup = () => {
        let timeSheet = [];

        for (let i = 0; i < this.state.repetitions; ++i) {
            if (i === 0 && this.state.warmup > 0) {
                timeSheet.push({
                    status: 0,
                    duration: this.state.warmup,
                    iteration: 0,
                });
            }

            timeSheet.push({
                status: 1,
                duration: this.state.intervalDur,
                iteration: i + 1,
            });

            if (i !== this.state.repetitions - 1 && this.state.pause > 0) {
                timeSheet.push({
                    status: 2,
                    duration: this.state.pause,
                    iteration: i + 1,
                });
            }
        }

        this.setState({
            timeSheet,
        }, () => {
            this.start();
        })
    }

    pause = () => {
        clearInterval(this.interval);
        this.setState({
            paused: true,
        })
    }

    start = () => {
        this.setState({
            started: true,
            paused: false,
        })
        this.interval = setInterval(() => {
            this.tick();
        }, 1000)
    }

    stop = (confirm = false) => {
        if (confirm && !window.confirm('Stop?')) return;
        clearInterval(this.interval);
        this.setState({
            started: false,
            paused: false,
            timer: 0,
            timeSheet: [],
        })
    }

    tick = () => {
        const timeSheet = this.state.timeSheet;
        const iteration = timeSheet[0];
        iteration.duration--;

        if (iteration.duration <= 0) {
            timeSheet.shift();
        }
    
        if (iteration.duration === 1) {
            setTimeout(() => {
                this.bleep1.src = 'audio/bleep2.mp3';
                this.bleep2.play();
            }, 900);
        }

        if (iteration.duration <= 4 && iteration.duration > 1) {
            setTimeout(() => {
                this.bleep1.src = 'audio/bleep1.mp3';
                this.bleep1.play();
            }, 900);
        }

        if (timeSheet.length === 0) {
            alert('Hooray!');
            this.stop();
        } else {
            this.setState({
                timeSheet,
                timer: this.state.timer + 1,
            })
        }
    }

    statusName = () => {
        if (!this.state.timeSheet[0]) return 'Settings';
        switch (this.state.timeSheet[0].status) {
            case 0:
                return 'Warmup'
            case 1:
                return 'Interval'
            case 2:
                return 'Pause'
            default:
                return 'Settings'
        }
    }

    render () {
        return (
            <div className={`container container--${this.statusName().toLowerCase()}`}>
                {!this.state.started &&
                    <div className="configuration">
                        <label>
                            <h2 className="configuration-title">Warmup</h2>
                            <input className="configuration-input" type="text" pattern="[0-9]*" min="0" value={this.state.warmup} onChange={(e) => this.change(e, 'warmup')} onBlur={(e) => this.validate(e, 'warmup')} />
                        </label>
                        <div>
                            <h2 className="configuration-title">Interval</h2>
                            <input className="configuration-input" type="text" pattern="[0-9]*" min="1" value={this.state.intervalDur} onChange={(e) => this.change(e, 'intervalDur')} onBlur={(e) => this.validate(e, 'intervalDur')} />
                        </div>
                        <div>
                            <h2 className="configuration-title">Pause</h2>
                            <input className="configuration-input" type="text" pattern="[0-9]*" min="0" value={this.state.pause} onChange={(e) => this.change(e, 'pause')} onBlur={(e) => this.validate(e, 'pause')} />
                        </div>
                        <div>
                            <h2 className="configuration-title">Repetitions</h2>
                            <input className="configuration-input" type="text" pattern="[0-9]*" min="1" max="500" value={this.state.repetitions} onChange={(e) => this.change(e, 'repetitions')} onBlur={(e) => this.validate(e, 'repetitions')} />
                        </div>
                        <div>
                            <p>{Math.floor(this.duration() / 60)}m {Math.floor(this.duration() % 60)}s</p>
                            <button onClick={this.setup}>Start</button>
                        </div>
                    </div>
                }
                {this.state.started &&
                    <div className={`timer timer--${this.statusName().toLowerCase()}`}>
                        <span>{this.statusName()}</span>
                        <span className="timer-countdown">{this.state.timeSheet[0].duration}</span>
                        <div className="timer-footer">
                            {!this.state.paused && <div className="timer-icon timer-icon--pause" onClick={this.pause}></div>}
                            {this.state.paused && <div className="timer-icon timer-icon--play" onClick={this.start}></div>}
                            <div>
                                {/*<p>{Math.floor(this.state.timer / 60)} minutes {Math.floor(this.state.timer % 60)} seconds</p>*/}
                                <p>{this.state.timeSheet[0].iteration} / {this.state.timeSheet[this.state.timeSheet.length - 1].iteration}</p>
                                <span>{Math.floor((this.duration() - this.state.timer) / 60)}m {Math.floor((this.duration() - this.state.timer) % 60)}s</span>
                                {/*<p>{Math.floor(this.duration() / 60)} minutes {Math.floor(this.duration() % 60)} seconds</p>*/}
                                {/*<p>{this.state.timer / this.duration() * 100}%</p>*/}
                            </div>
                            <div className="timer-icon timer-icon--stop" onClick={() => this.stop(true)}></div>
                        </div>
                        <div className="timer-progress" style={{ width: this.state.timer / this.duration() * 100 + '%' }}></div>
                    </div>
                }
                <div className="container-bg"></div>
            </div>
        );
    }
};
